import React, { useState, Fragment, useEffect } from 'react'
import Finance from './pages/Finance'
import Order from './pages/Order'
import { Route, BrowserRouter } from 'react-router-dom'
import UserNavBar from 'components/UserNavBar'
import NavBar from 'components/NavBar'
import { useSelector, useDispatch } from 'react-redux'
import { Drawer } from 'antd'
import { Dispatch, RootState } from './store'
import { Scene } from 'types/app'
import './customAntd.less'
import './config/i18n'


const root = document.getElementById('root')

function App() {
  const { app: { configure } } = useSelector((state: RootState) => state)
  const [isMini, setIsMini] = useState<boolean>((root!.offsetWidth < 1395))
  const appDispatch = useDispatch<Dispatch>().app

  window.onresize = function () {
    setIsMini((root!.offsetWidth < 1395))
  }

  useEffect(() => {
    setTimeout(() => appDispatch.setConfigure({ scene: Scene.johnson }), 0)
  }, [appDispatch])

  return (
    <Fragment>
      <div className='App'>
        <BrowserRouter>
          <div className='g-body'>
            <Drawer></Drawer>
            <div className='g-nav'>
              <NavBar inlineCollapsed={isMini} />
            </div>
            <div className='g-main' style={{ marginLeft: isMini ? '85px' : '257px' }}>
              <UserNavBar />
              {configure ?
                <Fragment>
                  <Route path='/finance' render={() => <Finance />}></Route>
                  <Route path='/order' render={() => <Order />}></Route>
                </Fragment>
                : '应用初始化界面，当初始化函数没加载完全时会呈现这种状态'
              }
            </div>
          </div>
        </BrowserRouter>
      </div>
    </Fragment>
  );
}

export default App
