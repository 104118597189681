import styled from "styled-components"

export const Body = styled.div`
  padding-top: 35px;
  position: relative;
`

export const Text = styled.p`
  position: absolute;
  transform: rotate(270deg);
  height: 20px;
  margin-bottom: 0;
  left: -25px;
  font-size: 12px;
  top: 140px;
`

export const Item = styled.div`
  background:rgba(0,0,0,0.75);
  padding:12px;
  border-radius:4px;
  position: relative;
`

export const ItemColor = styled.div`
  position: absolute;
  height: 6px;
  width: 6px;
  left: 13px;
  top: 37px;
  border-radius: 3px;
`

export const ItemTitle = styled.p`
  color: white;
  margin: 0;
`

export const ItemText = styled.p`
  margin: 10px 0 0 15px;
  color: white;
`