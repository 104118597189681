import React, { FC, useState, useCallback, useEffect } from "react"
import { Modal, Select, Button, Input } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { InputBox } from "components"
import { Tab } from "types/order"
import { baseURL } from "consts/url"
import { order } from "services"
import { State } from "types/app"
import qs from "qs"
import { Body, Text } from "./style"


interface ExportDialogType {
  tabList: Tab[]
  isOpen: boolean,
  onClose: () => void
}

const ExportDialog: FC<ExportDialogType> = (props) => {
  const { Option } = Select
  const [tabList, setTabList] = useState<string[]>()
  const [stateList, setStateList] = useState<{ [key: string]: string[] }>()
  const [selectTab, setSelectTab] = useState<string>()
  const [selectState, setSelectState] = useState<string>()
  const [search, setSearch] = useState<string>('')
  const [state, setState] = useState<State>(State.init)

  //重制ready
  useEffect(() => { setState(State.init) }, [search, selectState, selectTab])

  //init
  useEffect(() => {
    setTabList(props.tabList.map(item => item.name))
    setSelectTab(props.tabList[0].name)
    let temp: { [key: string]: string[] } = {}
    props.tabList.forEach(item => { temp[item.name] = item.options })
    setStateList(temp)
    setSelectState(temp[props.tabList[0].name][0])
  }, [props.tabList])

  const handleClickRule = useCallback(async () => {
    setState(State.loading)
    const data = await order.getList({
      status: selectState,
      q: search,
      page_size: '10',
      page: '1'
    })
    setState(data.total ? State.resolve : State.reject)
  }, [search, selectState])

  const renderButton = useCallback(() => {
    switch (state) {
      case State.init:
        return <Button type="primary">
          <Text onClick={handleClickRule}>验证数据</Text>
        </Button>
      case State.loading:
        return <Button type="primary" disabled>
          <Text ><LoadingOutlined /></Text>
        </Button>
      case State.resolve:
        return <Button type="primary">
          <Text
            href={`${baseURL}/Financial/Order/order_list_excel?${qs.stringify({ status: selectState, q: search })}`}
            download="temp"
          >导出</Text>
        </Button>
      case State.reject:
        return <Button type="primary" disabled>
          <Text>无数据</Text>
        </Button>
    }
  }, [state, selectState, search, handleClickRule])

  return (
    <Modal
      title="导出报表"
      visible={props.isOpen}
      onCancel={props.onClose}
      footer={
        <div>
          {renderButton()}
          <Button>
            <Text onClick={() => { }}>重置</Text>
          </Button>
        </div>
      }
    >
      <Body>
        <InputBox style={{ margin: 16 }} title="订单分类">
          <Select
            value={selectTab}
            onChange={(value) => {
              setSelectTab(value)
              setSelectState(stateList && stateList[value] && stateList[value].length ? stateList[value][0] : void 0)
            }}
            style={{ width: 256 }}
          >
            {tabList && tabList.map(item => <Option key={item} value={item}>{item}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="订单状态">
          <Select value={selectState} onChange={(value) => { setSelectState(value) }} placeholder='请选择' style={{ width: 256 }}>
            {stateList && selectTab && stateList[selectTab].map(item => <Option key={item} value={item}>{item}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="搜索订单">
          <Input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='请输入关键字' style={{ width: 256 }} />
        </InputBox>
      </Body>
    </Modal>
  )
}

export default ExportDialog