import React, { FC, useState, useEffect } from "react"
import { Chart, Legend, Axis, Tooltip, Geom } from "bizcharts"
import { LineResult } from "services/finance";
import { Body, Text } from "./style";
import ChartItem from "./chartItem";

interface LineModelType {
  data?: LineResult[]
}

interface LineData {
  name: string,
  time: string,
  amount: number,
  fluctuate: number,
}

const LineModel: FC<LineModelType> = (props) => {
  const [data, setData] = useState<LineData[]>()

  useEffect(() => {
    if (!props.data || !props.data.length) return
    const result: LineData[] = []
    props.data.forEach(line => {
      const name = line.name
      for (let time in line.item) {
        result.push({
          name,
          time,
          amount: line.item[time].amount / 100,
          fluctuate: line.item[time].incr_rate
        })
      }
    })
    setData(result)
  }, [props.data])

  if (!data?.length) {
    return <div></div>
  }

  return (
    <Body>
      <Text>流水金额（元）</Text>
      <Chart height={280} width={730} data={data} forceFit>
        <Legend
          position="right"
        />
        <Axis name="time" />
        <Axis name="amount" />
        <Tooltip
          showTitle
          showMarkers
          showCrosshairs
          follow
        >
          {(_title, item) => {
            if (!item || !item.length) return <div />
            const data: { data: LineData, color: string } = item[0]
            return <ChartItem
              color={data.color}
              title={`${data.data.time} ${data.data.name}`}
              price={data.data.amount}
              fluctuate={data.data.fluctuate}
            />
          }}
        </Tooltip>
        <Geom type="line" position="time*amount" size={2} color={'name'} />
        <Geom
          type="point"
          position="time*amount"
          size={4}
          shape={'circle'}
          color={'name'}
          style={{
            stroke: '#fff',
            lineWidth: 1,
          }}
        />
      </Chart>
    </Body>
  )
}

export default LineModel