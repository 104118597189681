import styled from "styled-components"

export const Body = styled.div`
  padding-left: 50px;
`

export const Text = styled.a`
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
`