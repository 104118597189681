import { ReactNode } from "react";

export enum Scene {
  johnson = 'johnson'
}

export enum State { init, loading, resolve, reject }

export interface Configure {
  scene: Scene
}

export interface alertDialog {
  isOpen: boolean,
  content: ReactNode,
}