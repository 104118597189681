import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { ossURL } from 'consts/url'
import { Body } from './style'
import { InsertRowAboveOutlined, CrownOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

interface MenuType {
  inlineCollapsed?: boolean
}

const NavBar: FC<MenuType> = (props) => {
  // const { app: { configure } } = useSelector((state: RootState) => state)
  const { SubMenu } = Menu
  return (
    <Body>
      <img
        src={`${ossURL}/img/${props.inlineCollapsed ? 'mini_' : ''}logo.png`}
        style={props.inlineCollapsed ?
          { width: '36px', margin: '19px 22px 44px' } :
          { width: '188px', margin: '19px 34px 44px' }
        }
      />
      <Menu
        style={{
          minHeight: '100%',
          borderRight: 'none'
        }}
        mode='inline'
        inlineCollapsed={props.inlineCollapsed}
      >
        <SubMenu key='sub2' title='订单管理' icon={<InsertRowAboveOutlined />}>
          <Menu.Item key='2'>
            <Link to='/order/list'>订单列表</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key='sub1' title='高级功能' icon={<CrownOutlined />}>
          <Menu.Item key='1'>
            <Link to='/finance/view'>财务模块</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Body >
  )
}

export default NavBar