import React, { FC, useState, useEffect } from "react"
import { Route, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { BreadcrumbOption, TabOption } from "types/antd"
import { Header } from "components"
import View from "./View/View"
import Detail from "./View/Detail"
import { finance } from "services"
import { Country } from "services/finance"

const Finance: FC = () => {
  const { t } = useTranslation()
  const { pathname } = useHistory().location
  const [country, setCountry] = useState<Country[]>([])
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbOption[]>([{
    name: "高级功能"
  }, {
    name: "财务模块"
  }])
  const [selectTabKey, setSelectTabKey] = useState<string>(pathname === "/finance/view" ? "0" : "1")
  const [tabs] = useState<TabOption[]>([{
    key: "0",
    name: "总览",
    link: "/finance/view"
  }, {
    key: "1",
    name: "详细报表",
    link: "/finance/list"
  }])

  useEffect(() => {
    if (selectTabKey === "0") {
      setBreadcrumbs([{
        name: "高级功能"
      }, {
        name: "财务模块"
      }, {
        name: "总览"
      }
      ])
    } else {
      setBreadcrumbs([{
        name: "高级功能"
      }, {
        name: "财务模块"
      }, {
        name: "详细报表"
      }])
    }
  }, [selectTabKey])

  useEffect(() => {
    (async () => {
      const country = await finance.getCountry()
      setCountry(country)
    })()
  }, [])

  return (
    <div>
      <Header
        title={t("page_finance_title")}
        description={t("page_finance_description")}
        breadcrumbs={breadcrumbs}
        tabs={tabs}
        selectTabKey={selectTabKey}
        onTabClick={(key) => { setSelectTabKey(key) }}
      />
      <Route path='/finance/view' render={() => <View countryList={country} />} />
      <Route path='/finance/list' render={() => <Detail countryList={country} />} />
    </div>
  )
}

export default Finance