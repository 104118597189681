import { post } from '../utils/http/http'

const baseURL = '/Financial/Order/'

export default {
  //列表-配置
  getConfigure: () => post<ConfigureResult>(`${baseURL}configure`),
  //列表-列表
  getList: (payload: ListPayload) => post<ListResult>(`${baseURL}orders`, payload),
  //详情-信息
  getDetail: (payload: { id: string }) => post<DetailResult>(`${baseURL}detail`, payload),
  //详情-动作
  action: (payload: ActionPayload) => post<any>(`${baseURL}action`, payload)
}

export interface ConfigureResult {
  status: { [key: string]: string[] }
  table: { [key: string]: string[] }
}

export interface ActionPayload {
  action: string,
  id: string[],
  payload: any
}

interface ListPayload {
  status?: string,
  q?: string,
  page_size: string,
  page: string
}

interface ListResult {
  total: number,
  orders: Order[]
}

export interface Order {
  cellphone: string,
  created: string,
  id: number,
  name: string,
  status: string,
  subject: string,
  supplier: string
}

export interface DetailResult {
  order_detail: Detail[],
  order_status: Logs;
  user: User;
  order_field: OrderField[];
}

export interface Detail {
  date: string,
  lesson: string,
  name: string,
  sequence_number: string,
  states: string,
  week: string
}

interface OrderField {
  name: string;
  field_content: string;
  type: number;
}

interface User {
  user_id: string;
  username: string;
  phone: string;
  department: string;
}

interface Logs {
  created: string;
  modified: string;
  status: string;
  supplier: string;
  logs: Log[];
}

interface Log {
  title: string;
  content: string;
}
