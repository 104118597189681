import React, { FC, useState, useEffect, useCallback, Fragment } from 'react'
import { order } from 'services'
import { DetailResult, ConfigureResult, ActionPayload } from 'services/order'
import { Button, Table, Popconfirm, message, Modal, Input } from 'antd'
import { columns, fieldColumns, infoStyle } from 'consts/order'
import { Block, Info } from 'components'
import { useParams } from 'react-router-dom'
import { Title, View, State } from './style'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'src/store'

const Detail: FC<{ configure?: ConfigureResult }> = (props) => {
  const { TextArea } = Input
  const { configure } = props
  const { setPage } = useDispatch<Dispatch>().order
  const param: { orderId: string } = useParams()
  const orderId = param.orderId
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [detail, setDetail] = useState<DetailResult>()
  const [render, setRender] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [actionName, setActionName] = useState<string>('')

  //获取详情
  useEffect(() => {
    orderId && configure && (async () => {
      setIsLoading(true)
      const data = await order.getDetail({ id: orderId })
      setIsLoading(false)
      setDetail(data)
    })()
  }, [configure, render, orderId])

  useEffect(() => {
    setContent('')
  }, [isOpen])

  //选择状态
  const renderStatus = useCallback(() => {
    if (!configure || !detail) return ''
    const { status } = detail.order_status
    let result = ''
    for (let name in configure.status) {
      if (configure.status[name].filter(item => item === status).length) {
        result = `${name}-${status}`
      }
    }
    return result
  }, [configure, detail])

  const onConfirm = useCallback(async (action) => {
    if (!detail) return
    setIsOpen(false)
    try {
      let payload: ActionPayload = {
        id: [orderId],
        action,
        payload: {}
      }
      switch (action) {
        case '同意': case '签到': case '同意收货': break
        default: payload.payload['content'] = content
      }
      await order.action(payload)
      setRender(render + 1)
      setPage(1)
      message.success(`您已完成操作'${action}'`, 5)
    } catch (err) {
      message.error(`操作出现异常`, 5)
    }
  }, [detail, orderId, render, content])

  //点击按钮
  const handleClickButton = useCallback((action) => {
    if (!detail || !orderId || isLoading) return
    setActionName(action)
    switch (action) {
      case '同意': case '签到': case '同意收货': onConfirm(action); return
      default: setIsOpen(true)
    }
  }, [detail, orderId, render, actionName, content])

  //渲染按钮
  const renderButton = useCallback((name) => (
    <Popconfirm placement="bottom" title={`您要'${name}'么`} onConfirm={() => handleClickButton(name)} okText={name} cancelText='暂不操作'>
      <Button style={{ marginLeft: '8px' }}>{name}</Button>
    </Popconfirm>
  ), [detail, orderId, render, actionName, content])

  //渲染按钮们
  const renderButtonList = useCallback(() => {
    if (!detail || !detail.order_status) return ''
    switch (detail.order_status.status) {
      case '待审核': return <Fragment>{renderButton('拒绝')}{renderButton('同意')}</Fragment>
      case '待签到': return <Fragment>{renderButton('取消')}{renderButton('签到')}</Fragment>
      case '已签到': return <Fragment>{renderButton('拒绝收货')}{renderButton('同意收货')}</Fragment>
    }
  }, [detail, orderId, render])

  return <React.Fragment>
    <Block
      loading={isLoading}
      title={<Title>订单编号:&nbsp;#{orderId}</Title>}
      description={<div>
        {renderButtonList()}
      </div>}
      style={{ paddingBottom: '15px' }}
    >
      <State>{renderStatus()}</State>
      {detail && detail.order_status && detail.order_status.logs && detail.order_status.logs.length ?
        <View style={{ marginTop: 0 }}>
          {detail.order_status.logs.map(item =>
            <Info title={item.title} style={infoStyle}>{item.content}</Info>
          )}
        </View>
        : null
      }
    </Block >
    <Block
      loading={isLoading}
      title={<Title>用户信息</Title>}
      style={{ padding: '14px 32px 16px' }}
    >
      <View>
        <Info title='用户姓名' style={infoStyle}>{detail?.user.username}</Info>
        <Info title='联系电话' style={infoStyle}>{detail?.user.phone}</Info>
        <Info title='供应商名称' style={infoStyle}>{detail?.user.department}</Info>
      </View>
    </Block >
    <Block
      loading={isLoading}
      title={<Title>订单信息</Title>}
      style={{ padding: '24px 32px 32px' }}
    >
      <Table
        columns={columns}
        dataSource={detail?.order_detail}
        pagination={false}
      />
    </Block >
    {detail && detail.order_field && detail.order_field.length ?
      <Block
        loading={isLoading}
        title={<Title>自定义预约信息</Title>}
        style={{ padding: '24px 32px 32px' }}
      >
        <Table
          bordered
          columns={fieldColumns}
          dataSource={detail?.order_field}
          pagination={false}
        />
      </Block >
      : null
    }
    <Modal
      visible={isOpen}
      title={actionName}
      onOk={() => onConfirm(actionName)}
      onCancel={() => setIsOpen(false)}
    >
      <View style={{ marginTop: 0 }}>
        <p>原因:</p>
        <TextArea
          rows={4}
          value={content}
          onChange={(e) => { setContent(e.target.value) }} />
      </View>
    </Modal>
  </React.Fragment>

}

export default Detail