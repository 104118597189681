export const tabList = [{
  key: '1',
  tab: '全部订单'
}, {
  key: '2',
  tab: '进行中'
}, {
  key: '3',
  tab: '退款中'
}, {
  key: '4',
  tab: '已关闭'
}]

export const columns = [{
  key: 'date',
  dataIndex: 'date',
  title: '预约日期'
}, {
  key: 'lesson',
  dataIndex: 'lesson',
  title: '时段'
}, {
  key: 'week',
  dataIndex: 'week',
  title: '日期'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '预约项目'
}, {
  key: 'sequence_number',
  dataIndex: 'sequence_number',
  title: '排位序号'
}, {
  key: 'states',
  dataIndex: 'states',
  title: '审核原因'
}]

export const fieldColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '信息名称'
}, {
  key: 'field_content',
  dataIndex: 'field_content',
  title: '信息内容'
}]

export const infoStyle = {
  flex: '0 0 33%',
  marginBottom: '15px'
}