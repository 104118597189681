import styled from "styled-components"

export const Body = styled.div`
  height: 455px;
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
`

export const Box = styled.div`
  width: 490px;
  position: relative;
`

export const Title = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.85);
  margin-bottom: 35px;
`

export const ChartInfo = styled.div`
   position: absolute;
   height: 100%;
   top: 0;
   right: 0;
   display: flex;
   align-items: center;
`

export const ChartList = styled.div`
  
`

export const Item = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 16px;
`

export const ItemColor = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
`

export const ItemName = styled.div`
  margin-left:8px;
  width: 56px;
  color: rgba(0,0,0,0.65);
`

export const ItemSpace = styled.div`
  width: 1px;
  height: 12px;
  background: #D9D9D9;
  margin: 0 10px;
`

export const ItemNumber = styled.div`
  color: rgba(0,0,0,0.45);
  width: 54px;
`

export const ItemPrice = styled.div`
  margin-left: 10px;

`