import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Title, Input } from './style'

interface SelectProps {
  title?: string,
  children?: ReactNode,
  style?: CSSProperties,
}

const InputBox: FC<SelectProps> = (props) => {
  return (
    <Body style={props.style}>
      <Title>{props.title}:</Title>
      <Input>{props.children}</Input>
    </Body>
  )
}

export default InputBox