import styled from "styled-components"

export const Body = styled.div`
  position: relative;
  height: 64px;
  margin-bottom: 2px;
  background: white;
  display:flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 24px;
`

export const Bar = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.p`
  margin-left:8px;
  margin-bottom: 0;
`