import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  padding: 12px 0;
  width: 1072px;
  position: relative;
`

export const Hr = styled.div`
  width:1072px;
  height:1px;
  background:#E9E9E9;
  position: absolute;
  top: -12px;
  left: 0;
`

export const ButtonBar = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;
`

export const Arrow = styled.div`
  width: 200px;
  height: 30px;
  line-height: 32px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h1`
  font-size: 16px;
  margin: 15px 3px 0;
`