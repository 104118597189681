import React, { FC } from "react"
import { Item, ItemColor, ItemName, ItemSpace, ItemNumber, ItemPrice } from "./style"


interface ChartItemProps {
  color: string,
  name: string,
  percent: string,
  price: string,
}

const ChartItem: FC<ChartItemProps> = (props) => (
  <Item>
    <ItemColor style={{ background: props.color }} />
    <ItemName>{props.name}</ItemName>
    <ItemSpace />
    <ItemNumber>{props.percent}</ItemNumber>
    <ItemPrice>{props.price}</ItemPrice>
  </Item>
)

export default ChartItem

