import React, { FC, ReactNode, CSSProperties } from 'react'

interface HeaderProps {
  title: string,
  style?: CSSProperties,
  children?: ReactNode
}

const Info: FC<HeaderProps> = (props) => (
  <p style={{ fontSize: '14px', ...props.style }}>
    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{props.title}:</span>
    <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>&nbsp;&nbsp;{props.children}</span>
  </p>
)

export default Info