export const originData = [{
  type: '余额支付',
  value: 0,
  color: '#3BA0FF',
  percent: 0
}, {
  type: '套餐支付',
  value: 0,
  color: '#36CBCB',
  percent: 0
}, {
  type: '微信支付',
  value: 0,
  color: '#975FE4',
  percent: 0
}]

export const usedData = [{
  type: '预约消费',
  value: 0,
  color: '#FAD337',
  percent: 0
}, {
  type: '商城购买',
  value: 0,
  color: '#F2637B',
  percent: 0
}, {
  type: '购买套餐',
  value: 0,
  color: '#1890FF',
  percent: 0
}, {
  type: '账户充值',
  value: 0,
  color: '#4DCB73',
  percent: 0
}]