import React, { FC, useEffect, useState, useCallback, Fragment } from 'react'
import { Block } from 'components'
import { Tabs, Select, Button, Input, Table, message } from 'antd'
import { order } from 'services'
import { Header, Nav, Title } from './style'
import { ConfigureResult, ActionPayload, Order } from 'services/order'
import { ExportDialog } from '../Model'
import { renderTableRow, renderTableCustom } from '../scene'
import { useHistory } from 'react-router'
import { Tab, Column } from 'types/order'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, Dispatch } from 'src/store'

const List: FC<{ configure?: ConfigureResult }> = (props) => {
  const { TabPane } = Tabs
  const { Option } = Select
  const { Search } = Input
  const { order: { selectTab, selectOption, pageSize, page } } = useSelector((state: RootState) => state)
  const { setSelectTab, setSelectOption, setPageSize, setPage } = useDispatch<Dispatch>().order
  const router = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [tabList, setTabList] = useState<Tab[]>()
  const [total, setTotal] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [columns, setColumns] = useState<Column[]>([])
  const [data, setData] = useState<any>([])
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [render, setRender] = useState<number>(1)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  //生成列名
  const handleColumns = useCallback((table: { [key: string]: string[] }) => {
    let result = []
    for (let title in table) {
      result.push({
        key: table[title].join(),
        dataIndex: table[title].join(),
        title
      })
    }
    result.push({
      key: 'custom',
      dataIndex: 'custom',
      title: '操作'
    })
    setColumns(result)
  }, [])

  //生成数据
  const handleData = useCallback((data: Order[]) => {
    if (!data || !data.length) return []
    const result: any[] = []
    data.forEach(order => {
      const item: any = {}
      columns.forEach(column => {
        const titleList = column.key.split(',')
        item[column.key] = renderTableRow(column.key, order, titleList)
      })
      item.key = order.id
      item.custom = renderTableCustom(item.key)
      result.push(item)
    })
    return result
  }, [columns])

  //请求列表
  useEffect(() => {
    console.log(router)
    columns && columns.length && (async () => {
      setLoading(true)
      const data = await order.getList({
        status: selectOption || '',
        q: search,
        page_size: pageSize.toString(),
        page: page.toString()
      })
      setLoading(false)
      setTotal(data.total)
      setData(handleData(data.orders))
    })()
  }, [page, pageSize, selectOption, search, columns, handleData, render])

  // init
  useEffect(() => {
    if (!props.configure) return
    setLoading(false)
    const { status, table } = props.configure
    let temp: Tab[] = []
    for (let name in status) { temp.push({ name, options: status[name] }) }
    temp.unshift({ name: '全部订单', options: [] })
    setTabList(temp)
    if (!selectTab) {
      setSelectTab(temp[0])
    }
    handleColumns(table)
  }, [handleColumns, props.configure])

  // 切换Tab事件
  const handleChangeTabs = useCallback((name) => {
    if (!tabList) return
    setPage(1)
    setSelectTab(tabList.filter(item => item.name === name)[0])
    setSelectOption(tabList.filter(item => item.name === name)[0].options[0])
    setSelectRowIdList([])
  }, [tabList])

  const renderButtonList = useCallback(() => {
    if (!selectTab) return null
    switch (selectTab.name) {
      case '待审核':
        return <Button onClick={onConfirm} style={{ marginLeft: 8 }} disabled={!(selectRowIdList && selectRowIdList.length)}>同意审核</Button>
    }
  }, [selectRowIdList, selectTab])

  const onConfirm = useCallback(async () => {
    try {
      let payload: ActionPayload = {
        id: selectRowIdList as string[],
        action: '同意',
        payload: {}
      }
      await order.action(payload)
      setPage(1)
      setRender(render + 1)
      message.success(`批量审核成功`)
    } catch (err) {
      message.success(`操作出现异常`)
    }
    setRender(render + 1)
  }, [render, selectRowIdList])

  return <Fragment>
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      {tabList && tabList.length ?
        <Tabs
          defaultActiveKey={selectTab?.name}
          type="card"
          onChange={handleChangeTabs}
          style={{ borderBottom: 'none' }}
        >
          {tabList.map(item => <TabPane key={item.name} tab={item.name} />)}
        </Tabs>
        : null
      }
      <Header>
        <Title>订单列表</Title>
        <Nav>
          <Select
            value={selectOption}
            style={{ width: 200 }}
            placeholder='全部列表'
            onChange={(value) => {
              setSelectOption(value)
              setSelectRowIdList([])
              setPage(1)
            }}
          >
            {selectTab && selectTab.options && selectTab.options.length ?
              selectTab.options.map(item => <Option key={item} value={item}>{item}</Option>)
              : null
            }
          </Select>
          <Search
            placeholder="input search text"
            onSearch={value => setSearch(value)}
            style={{ width: 320, marginLeft: 8 }}
          />
          <Button type='primary' style={{ marginLeft: 8 }} onClick={() => { setIsOpen(true) }}>导出EXCEL</Button>
          {renderButtonList()}
        </Nav>
      </Header>
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          selectedRowKeys: selectRowIdList,
          onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
        }}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{
          marginTop: 24
        }}
      />
    </Block >
    {tabList ?
      <ExportDialog
        tabList={tabList}
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
      />
      : null
    }

  </Fragment>
}

export default List