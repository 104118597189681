import styled from 'styled-components'

export const Header = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  margin-bottom: 0;
  font-size: 16px;
  color: rgba(0,0,0,0.85);
`

export const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 20px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`

export const State = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  height: 70px;
  font-weight: 600;
`
