import styled from "styled-components"

export const Body = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;  
  margin-right: 5px;
`

export const Input = styled.div`
  
`