import React, { FC } from 'react'
import { Tooltip as Description } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"

interface BlockProps {
  title: string,
}

const TooltipIcon: FC<BlockProps> = (props) => {
  return (
    <Description placement="rightTop" title={props.title}>
      <InfoCircleOutlined style={{ color: "#cacaca" }} size={30} />
    </Description>
  )
}

export default TooltipIcon