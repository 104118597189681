import React from "react"
import { Item } from "./View/style"
import { Popover } from "antd"
import { Link } from "react-router-dom"
import { scene } from "utils/scene"

const handleTimeView = (timeList: string[]) => {
  if (timeList.length > 1) {
    return <Popover content={timeList.map(item => <div key={item}>{item}</div>)}>
      <span>{timeList[0]}&nbsp;...</span>
    </Popover>
  } else {
    return timeList
  }
}

export const renderTableRow = (key: string, data: any, titleList: string[]) => scene({
  johnson: () => {
    if (key === 'subject,time') {
      return <Item>
        <div>{data['subject']} </div>
        {data['time'] && data['time'].length ? <div>{handleTimeView(data['time'])} </div> : null}
      </Item>
    } else {
      return <Item>{titleList.map(title => <div key={`${Math.random()}-${key}-${title}`} > {data[title]} </div>)}</Item>
    }
  }
})

export const renderTableCustom = (key: string) => scene({
  johnson: () => <Item>< Link to={`/order/detail/${key}`}> 查看详情</Link ></Item >
})
