import { get, post } from '../utils/http/http'

const baseURL = '/Financial/Index/'

export default {
  //门店
  getCountry: () => get<Country[]>(`${baseURL}country`),
  //总览-饼图
  getChart: (payload: ChartInfoPayload) => post<ChartResult>(`${baseURL}pie`, payload),
  //总览-折线图
  getLine: (payload: { range: number }) => post<LineResult[]>(`${baseURL}line`, payload),
  //详细报表-列表
  getList: (payload: ListPayload) => post<ListResult>(`${baseURL}financial_list`, payload),
}

export interface Country {
  id: string,
  name: string
}

export interface ListPayload {
  branch?: string,
  type?: string,
  method?: string,
  q?: string,
  start?: string,
  end?: string,
  page?: number,
  page_size?: number,
}

export interface ListResult {
  orders: Order[],
  total_num: number
}

export interface Order {
  order_no: string;
  username: string;
  name: string;
  time: string;
  type: string;
  method: string;
  amount: string;
  payed: string;
  flowing: string;
}


interface ChartInfoPayload {
  branch?: string,
  start: string,
  end?: string
}

export interface ChartResult {
  flowing_water: number;
  turnover: number;
  total: Total[];
  revenue: Revenue[];
}

interface Revenue {
  type: string;
  value: number;
  percent: number;
}

interface Total {
  type: string;
  value: number | string;
  percent: number;
}

export interface LineResult {
  name: string;
  id: string;
  item: LineItem;
}

interface LineItem {
  [key: string]: lineTimeItem
}

interface lineTimeItem {
  amount: number;
  incr_rate: number;
}