import { createModel } from '@rematch/core'


interface FinanceState {

}

export const FinanceState = createModel({
  state: {
  } as FinanceState,
  reducers: {
  }
})