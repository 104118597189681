import React, { FC, useState, useCallback, useEffect } from "react"
import { Modal, Select, DatePicker, Button } from "antd"
import { InputBox } from "components"
import { FormatDay } from "utils/time"
import moment from "moment"
import { orderList, payList } from "consts/finance"
import { Country, ListPayload } from "services/finance"
import { Body, Text } from "./style"
import { baseURL } from "consts/url"
import qs from "qs"

interface DialogType {
  countryList: Country[],
  isOpen: boolean,
  onClose: () => void
}

const Dialog: FC<DialogType> = (props) => {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const nowDate = FormatDay(new Date())
  const [startTime, setStartTime] = useState<string>(nowDate)
  const [endTime, setEndTime] = useState<string>(nowDate)
  const [country, setCountry] = useState<string>('')
  const [orderType, setOrderType] = useState<string>('')
  const [payType, setPayType] = useState<string>('')
  const [payload, setPayload] = useState<ListPayload>({
    start: nowDate,
    end: nowDate,
  })

  //设置参数
  useEffect(() => {
    setPayload({
      branch: country,
      type: orderType,
      method: payType,
      start: startTime,
      end: endTime,
    })
  }, [startTime, endTime, orderType, payType, country, nowDate])

  //重置
  const reset = useCallback(() => {
    setCountry('')
    setOrderType('')
    setPayType('')
    setStartTime(nowDate)
    setEndTime(nowDate)
  }, [nowDate])

  return (
    <Modal
      title="导出报表"
      visible={props.isOpen}
      onCancel={props.onClose}
      footer={<div>
        <Button type="primary">
          <Text href={`${baseURL}/Financial/Index/financial_download?${qs.stringify(payload)}`} download="temp">导出</Text>
        </Button>
        <Button>
          <Text href='javascript:0' onClick={reset}>重置</Text>
        </Button>
      </div>}
    >
      <Body>
        <InputBox style={{ margin: 16 }} title="时间范围">
          <RangePicker
            defaultValue={[moment(startTime), moment(endTime)]}
            onChange={(_date, dateString) => {
              if (dateString && dateString.length) {
                setStartTime(dateString[0])
                setEndTime(dateString[1])
              }
            }}
          />
        </InputBox>
        <InputBox style={{ margin: 16 }} title="消费类型">
          <Select value={orderType} onChange={(value) => { setOrderType(value) }} style={{ width: 256 }}>
            {orderList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="支付方式">
          <Select value={payType} onChange={(value) => { setPayType(value) }} placeholder='请选择' style={{ width: 256 }}>
            {payList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        </InputBox>
        <InputBox style={{ margin: 16 }} title="消费门店">
          <Select value={country} onChange={(value) => setCountry(value)} style={{ width: 256 }}>
            <Option value=''>全部门店</Option>
            {props.countryList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
          </Select>
        </InputBox>
      </Body>
    </Modal>
  )
}

export default Dialog