import { createModel } from '@rematch/core'
import { Configure } from 'types/app'

interface AppState {
  configure: Configure
}

export const app = createModel({
  state: {} as AppState,
  reducers: {
    setConfigure: (state, payload: Configure): AppState => ({
      ...state,
      configure: payload
    })
  },
  effects: {

  }
})

