import React, { FC, useState, useEffect } from 'react'
import { ChartResult, LineResult, Country } from 'services/finance'
import { finance } from 'services'
import { DatePicker, Select } from 'antd'
import { Block } from 'components'
import { FormatDay } from 'utils/time'
import { ChartModel, LineModel } from '../Model'
import moment from 'moment'

interface ViewType {
  countryList: Country[]
}

const View: FC<ViewType> = (props) => {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const nowDate = new Date()
  const [startTime, setStartTime] = useState<string>(FormatDay(nowDate))
  const [endTime, setEndTime] = useState<string>(FormatDay(nowDate))
  const [chart, setChart] = useState<ChartResult>()
  const [chartLoading, setChartLoading] = useState<boolean>(true)
  const [line, setLine] = useState<LineResult[]>()
  const [lineLoading, setLineLoading] = useState<boolean>(true)
  const [lineSelect, setLineSelect] = useState<number>(7)
  const [countryLoading, setCountryLoading] = useState<boolean>(true)
  const [countryList, setCountryList] = useState<Country[]>([
    { name: "所有门店总计", id: "" }])
  const [country, setCountry] = useState<string>("")
  
  //设置城市信息
  useEffect(() => {
    if (props.countryList.length) {
      setCountryLoading(false)
      setCountryList([{ name: "所有门店总计", id: "" }, ...props.countryList])
    }
  }, [props, setCountryList, setCountryLoading])

  //请求饼图数据
  useEffect(() => {
    (async () => {
      setChartLoading(true)
      const data = await finance.getChart({
        branch: country,
        start: startTime,
        end: endTime
      })
      setChartLoading(false)
      setChart(data)
    })()
  }, [startTime, endTime, country])

  //处理折线图时间
  // const handleLinePayload = useCallback((time) => {
  //   const start = FormatDay(nowDate)
  //   const end = FormatDay(getLastDay(nowDate, time))
  //   return { start, end }
  // }, [])

  //请求折线图数据
  useEffect(() => {
    (async () => {
      setLineLoading(true)
      const data = await finance.getLine({ range: lineSelect })
      setLineLoading(false)
      setLine(data)
    })()
  }, [lineSelect])

  return (
    <React.Fragment>
      <Block
        title={
          <Select value={country} style={{ width: 200 }} onChange={(value) => setCountry(value)}>
            {countryList.map(item => <Option value={item.id}>{item.name}</Option>)}
          </Select>}
        loading={chartLoading}
        description={
          <div>
            起止时间:&nbsp;&nbsp;<RangePicker
              defaultValue={[moment(startTime), moment(endTime)]}
              onChange={(_date, dateString) => {
                if (dateString && dateString.length) {
                  setStartTime(dateString[0])
                  setEndTime(dateString[1])
                }
              }}
            />
          </div>
        }
      >
        <ChartModel data={chart} />
      </Block>
      <Block
        title='各门店折线图统计'
        loading={lineLoading}
        description={
          <Select
            value={lineSelect}
            style={{ width: 200 }}
            onChange={(value) => setLineSelect(value)}
            loading={countryLoading}
          >
            <Option value={7}>近七日流水金额趋势</Option>
            <Option value={15}>近十五日流水金额趋势</Option>
            <Option value={30}>近三十日流水金额趋势</Option>
          </Select>
        }
      >
        <LineModel data={line}></LineModel>
      </Block>
    </React.Fragment>
  )
}

export default View