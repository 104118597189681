import React, { FC } from "react"
import { Chart, Coordinate, Axis, Tooltip, Interval, Legend } from "bizcharts"
import TooltipIcon from "components/Tooltip"
import { Box, Title, ChartList, ChartInfo } from "./style"
import { ChartData } from "."
import ChartItem from "./ChartItem"

interface ViewProps {
  data: ChartData[],
  title: string,
  description: string,
}

const View: FC<ViewProps> = (props) => {
  const { data, title, description } = props
  return (
    <Box>
      <Title>
        {title}&nbsp;&nbsp;<TooltipIcon title={description} />
      </Title>
      <Chart data={data} height={250} width={250} autoFit >
        <Coordinate type="theta" radius={0.95} innerRadius={0.75} />
        <Axis visible={false} />
        <Tooltip showTitle={false} />
        <Interval
          adjust="stack"
          position="value"
          color={['type', data.map(item => item.color)]}
          shape="sliceShape"
        />
        <Legend visible={false} />
      </Chart>
      <ChartInfo>
        <ChartList>
          {data.map(item =>
            <ChartItem
              key={item.type}
              color={item.color}
              name={item.type}
              percent={`${item.percent}%`}
              price={`¥${item.value}`}
            />)}
        </ChartList>
      </ChartInfo>
    </Box>
  )
}

export default View

