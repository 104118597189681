import { createModel } from '@rematch/core'
import { Configure } from 'types/app'
import { Tab } from 'types/order'

interface OrderState {
  selectTab: Tab,
  selectOption: string,
  pageSize: number,
  page: number
}

export const order = createModel({
  state: {
    pageSize: 20,
    page: 1,
  } as OrderState,
  reducers: {
    setConfigure: (state, payload: Configure): OrderState => ({
      ...state,
      configure: payload
    }),
    setSelectTab: (state, payload: Tab): OrderState => ({
      ...state,
      selectTab: payload
    }),
    setSelectOption: (state, payload: string): OrderState => ({
      ...state,
      selectOption: payload
    }),
    setPageSize: (state, payload: number): OrderState => ({
      ...state,
      pageSize: payload
    }),
    setPage: (state, payload: number): OrderState => ({
      ...state,
      page: payload
    })
  }
})