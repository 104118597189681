import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Header, Title, Main, Loading } from './style'
import { Spin } from 'antd'


interface BlockProps {
  title?: ReactNode,
  description?: ReactNode,
  children?: ReactNode,
  loading?: boolean,
  style?: CSSProperties
}

const Block: FC<BlockProps> = (props) => {
  return (
    <Body>
      {
        props.title || props.description ?
          <Header>
            <Title>{props.title ? props.title : <div />}</Title>
            <div>{props.description ? props.description : <div />}</div>
          </Header>
          : null
      }
      <Main style={props.style}>{props.children}</Main>
      {props.loading ? <Loading><Spin size="large" /></Loading> : null}
    </Body>
  )
}

export default Block