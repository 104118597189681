import React, { FC } from 'react'
import { Body, Bar, Name } from './style'
import { MenuFoldOutlined, SearchOutlined, BellOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'

const UserNavBar: FC = () => {
  return (
    <Body>
      <MenuFoldOutlined style={{ fontSize: '24px' }} />
      <Bar>
        <SearchOutlined />
        <BellOutlined style={{ marginLeft: '24px' }} />
        <Avatar style={{ marginLeft: '24px' }} ></Avatar>
        <Name >这里是名字</Name>
      </Bar>
    </Body>
  )
}

export default UserNavBar