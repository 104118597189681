import React, { FC } from "react"
import { Item, ItemTitle, ItemColor, ItemText } from "./style"

interface ChartItemProps {
  color: string,
  title: string,
  price: number
  fluctuate: number
}

const ChartItem: FC<ChartItemProps> = (props) => (
  <Item>
    <ItemTitle>{props.title}</ItemTitle>
    <ItemColor style={{ background: props.color }}></ItemColor>
    <ItemText>当日流水金额:&nbsp;{props.price}</ItemText>
    <ItemText>增长比例:&nbsp;{props.fluctuate}</ItemText>
  </Item>
)

export default ChartItem
