export const columns = [{
  key: 'order_no',
  dataIndex: 'order_no',
  title: '订单号'
}, {
  key: 'username',
  dataIndex: 'username',
  title: '用户名'
}, {
  key: 'name',
  dataIndex: 'name',
  title: '商品名'
}, {
  key: 'time',
  dataIndex: 'time',
  title: '消费时间'
}, {
  key: 'type',
  dataIndex: 'type',
  title: '消费类型'
}, {
  key: 'method',
  dataIndex: 'method',
  title: '支付方式'
}, {
  key: 'amount',
  dataIndex: 'amount',
  title: '应收'
}, {
  key: 'payed',
  dataIndex: 'payed',
  title: '实收'
}, {
  key: 'flowing',
  dataIndex: 'flowing',
  title: '流水'
}]

export const orderList = [{
  id: '',
  name: '全部类型'
}, {
  id: '1',
  name: '预约'
}, {
  id: '2',
  name: '商品'
}, {
  id: '3',
  name: '购买套餐'
}, {
  id: '4',
  name: '充值'
}, {
  id: '5',
  name: '计时计费'
}]

export const payList = [{
  id: '',
  name: '全部支付方式'
}, {
  id: '3',
  name: '余额支付'
}, {
  id: '4',
  name: '套餐核销'
}, {
  id: '2',
  name: '微信支付'
}]